export default {
    getProducts() {
        return window.api.get('/products');
    },

    importProductsWithCustomFeed(data) {
        return window.api.post('products/custom-import', data);
    },

    deleteProduct(id) {
        window.api.delete(`/products/delete/${id}`);
    },

    getReviews() {
        return window.api.get('/reviews');
    },

    getAttributes() {
        return window.api.get('/products/attributes');
    },

    verifyProducts() {
        return window.api.post('/products/verify_products');
    }
}