<template>
	<RecordViewWrapper>
		<sdPageHeader>
			<template #buttons
				><div class="search-box">
					<span class="search-icon">
						<sdFeatherIcons type="search" size="14" />
					</span>
					<input
						v-model="search"
						type="text"
						name="recored-search"
						placeholder="Search products by product name"
					/></div
			></template>
		</sdPageHeader>
		<Main>
			<a-row :gutter="15">
				<a-col class="w-100" :md="24">
					<sdCards headless>
						<div v-if="store.state.actions.isLoading" class="spin">
							<a-spin />
						</div>

						<div v-else>
							<TableWrapper class="table-data-view table-responsive">
								<a-table
									:rowSelection="rowSelection"
									:pagination="{ pageSize: 10, showSizeChanger: true }"
									:dataSource="filterSearch"
									:columns="columns"
								/>
							</TableWrapper>
						</div>
					</sdCards>
				</a-col>
			</a-row>
		</Main>
	</RecordViewWrapper>
</template>
<script>
import { RecordViewWrapper } from "@/components/table/style";
import ProductService from "@/services/ProductService";
import store from "@/vuex/store";
import { splitProductName } from "../../utility/utility";

export default {
	name: "ProductsList",

	components: {
		RecordViewWrapper
	},

	data() {
		return {
			dataSource: [],
			columns: [
				{
					title: "Image",
					dataIndex: "image_url",
					key: "image_url"
				},
				{
					title: "Product name",
					dataIndex: "product_url",
					key: "product_url"
				},
				{
					title: "Price",
					dataIndex: "price",
					key: "price"
				},
				{
					title: "Price with discount",
					dataIndex: "price_with_discount",
					key: "price_with_discount"
				},
				{
					title: "Categories",
					dataIndex: "categories",
					key: "categories"
				},
				{
					title: "Sku",
					dataIndex: "sku",
					key: "sku"
				},
				{
					title: "Actions",
					dataIndex: "actions",
					key: "actions"
				}
			],
			store: store,
			search: ""
		};
	},

	computed: {
		filterSearch() {
			return this.dataSource.filter(product => {
				return product.product_name.toLowerCase().includes(this.search);
			});
		}
	},

	mounted() {
		this.getProducts();
	},

	methods: {
		async getProducts() {
			this.$store.dispatch("actions/setLoading", true);
			const products = (await ProductService.getProducts()).data.products;
			this.dataSource = products.map((product, key) => {
				return {
					key: key + 1,
					image_url: (
						<div class="record-img align-center-v">
							<img
								src={product.image_url}
								alt={product.product_name}
								width="100"
								height="100"
							/>
						</div>
					),
					product_name: product.product_name,
					product_url: <a href={product.product_url} target="_blank"><br/>{product.product_name}</a>,
					price: `${product.price} ${product.currency}`,
					price_with_discount: product.price_with_discount > 0 ? `${product.price_with_discount} ${product.currency}` : "",
					categories: product.categories,
					sku: product.sku,
					actions: (
						<div class="table-actions">
						<a href="javascript:void(0)" onClick={() => this.deleteProduct(product.id)}><i class="" style="line-height: 0;"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2" color=""><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></i></a>
						</div>
          			)
				};
			});
			this.$store.dispatch("actions/setLoading", false);
		},

    	async deleteProduct(id) {
			await ProductService.deleteProduct(id);

			window.showAlert(
				"success",
				"Success",
				"Product deleted successfully"
			);

			await this.getProducts();
    	}
	}
}
</script>
