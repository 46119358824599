/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

/**
 * 
 * @param {*} str 
 * @returns {str}
 */
const parseCookie = (str) => {
  return str
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());
      return acc;
    }, {});
};

const splitProductName = (productName) => {
  const maxCharLimit = 15;
  if (productName.length > maxCharLimit) {
    const firstLine = productName.substr(0, productName.lastIndexOf(" ", maxCharLimit));
    const secondLine = productName.substr(firstLine.length);
    console.log(secondLine);
    return `${firstLine}<br>${secondLine}`;
  } else {
    return productName;
  }
}

export { ellipsis, parseCookie, splitProductName };
