<template>
	<sdPageHeader title="Products" />
	<Main>
		<a-row :gutter="25">
			<a-col :xs="24">
				<sdCards headless>
					<sdCards>
						<ProductsList />
					</sdCards>
				</sdCards>
			</a-col>
		</a-row>
	</Main>
</template>

<script>
import { Main } from "../styled";
import ProductsList from "./ProductsList.vue";

export default {
	name: "Products",
	components: {
		Main,
		ProductsList
	}
};
</script>
